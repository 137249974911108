li {
    padding: 10px;
}
ul {
    padding-left: 5px;
    margin-top: 5px
}

 .time-heading {
    margin: 10px 0;
    text-align: center;
    color:  #566573;
} 

.time {
     margin: 10px 0;
    text-align: center;
    font-weight: 300; font-size: 43px;
    color: #fc4c79;
}
.qpanel > * img {
   background: white; 
}

.qpanel {
    overflow-y: scroll ;
    padding: 10px;
    margin-top: 1em;
    min-height: calc(100vh - 250px);
    max-height:calc(100vh - 250px); 
    background-color: white;
    padding-right: 8px;
}



[class^="HomePanel-btn"],
[class*="HomePanel-btn"]  {
    padding: 1% 2%;
    min-width: 75%;
    font-size: 20px;
    border-radius: 3px;
    cursor: pointer;
   
}
[class^="HomePanel-btn"],
[class*="HomePanel-btn"]{
    border: 1px solid 'skyblue'
} 

td[class*="Results-tablelines"],th[class*="Results-tablelines"]{
       min-width: 30px;
    text-align: center;

}
button[class*="btnreview"]:hover  {
    background-color: #2e6da4 !important
}

button[class*="btnwarning"]:hover  {
    background-color: #eea236 !important
}

button[class*="btnsuccess"]:hover  {
    background-color: #4cae4c !important
}

.question {
    color: #535d8c;
    margin: 1% 0;
}

.jee-heading {
    color: #2f3a8f;
    padding: 6px 30px;
    margin-left: 100px;
    font-size: 30px;
}

img {
    vertical-align: middle
}

.btn-back,.btn-next{
    /* width: 10em; */
    /* height: 3em; */
    color: white;
    background: grey;
    border-radius: 5px;
}
.btn-back:hover,.btn-next:hover{
   color:black;
}


.btn-submit {
    background: green;
    /* width: 10em; */
    /* height: 3em; */
    /* height: 4em; */
    color: white;
    /* border-radius: 5px; */
    /* margin-right: 10%; */
}
.btn-submit:hover{
    /* color: black; */
    background: darkgreen !important;
    /* margin-left:80%; */
}
/* .checked {
     /* color: #535d8c !important;  
} */
.progress{
    color: blue
}
/* #student-math{
    
} */
/*#f8931e for subjects  */

/* save & next #1ba651  */

/* clear  #303a90 */


/* subject text #566573  */


/* jee main #2f3a8f  */


/* visited #dd4430  */