html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Quicksand", sans-serif !important
}

body {
  margin: 0;
  padding: 0;
  background-color: #EBEFF3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: #fafafa;
}

.App {
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

Fab {
  vertical-align: middle;
}

.react-photo-gallery--gallery div {
  flex-flow: nowrap !important;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

strong,
b {
  font-weight: 700;
}

@media print {
  body {
    background-color: #fff;
  }
}

body::backdrop {
  background-color: #fafafa;
}

.MuiTypography-root {
  font-family: "Quicksand", sans-serif !important
}

.MuiButton-label {
  font-family: "Quicksand", sans-serif !important;
  font-weight: 600;
}

/* .MuiDialog-container{
  background: #EBEFF3
} */

@keyframes blink {
  0% {
      opacity: 1;
  }

  50% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}