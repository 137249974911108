.tabContainer {
    margin-left: -2%;
    width: 90vw !important;
    overflow: auto;
    white-space: nowrap;
}

.tabContainer::-webkit-scrollbar {
    display: none
}

.tabContainer {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.videoTopicName{
    width: 70%;
    display: flex
}
@media(max-width: 600px){
    .tabContainer{
        margin-left: 0;
    }
}
