#play_here * {
    display: block;
}

#play_here [class="*video-player"] {
    display: block;
}

.r-header {
    display: none;
}

#play_here>*>.video-player {
    display: block;
}

.yt_player {
    width: 100% !important;
    height: 500px;
}