button[class*="NumbersPanel"]:hover{
     background-color: initial !important 
}
/* button {
    box-shadow: none !important;
    background-color: white !important;
} */

.not-visited {

}

.visited {

}

.indexPanel {
    background: white;
    overflow-y: scroll;
    height: 21em;
    padding: 10px;
    margin-top: .5em;
}

button[class*=NumbersPanel-fab]{
    /* border-radius: 0px;
    background-color: unset;      
    width: 40px;
    font-size: 14px;
    font-weight: bolder;
    box-shadow: none;
    background-size: 90% 90% !important; */
}
button.indexButton{
   cursor: pointer;
}

button[class*=not-visited]{
    border: 0px;
    color: #474747;
    background: url('/images/SVG/not-visited.svg'); 
    background-repeat: no-repeat;
}
button[class*=answered]{
    border: 0px;
    color:white;
    background: url('/images/SVG/answered.svg');
    background-repeat: no-repeat;
}
button[class*=not-answered]{
    border: 0px;
     color:white;
     background: url('/images/SVG/not-answered.svg');
     background-repeat: no-repeat;
}

button[class*=marked-for-review]{
     border: 0px;
     color:white;
     background: url('/images/SVG/mark-for-review.svg');
     background-repeat: no-repeat;
}

button[class*=answered-marked-for-review]{
     border: 0px;
     color:white;
     background: url('/images/SVG/answered_marked_for_review.svg');
     background-repeat: no-repeat;
}