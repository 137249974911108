div[class*="SectionContacts-textField"] input::placeholder, div[class*="SectionContacts-textField"] textarea::placeholder {
      
    font-family: "Montserrat";
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
   
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: rgba(49, 69, 106, 0.5);
}

div[class*="SectionContacts-textField"] > input, textarea{
    margin: 14px
}

div[class*="SectionContacts-textField"]  textarea{
    margin: 14px
}