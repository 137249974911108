button[class*="NumbersPanel"]:hover {
    background-color: initial !important
}

/* button {
    box-shadow: none !important;
    background-color: white !important;
} */

.indexPanelAdv {
    background: white;
    overflow-y: scroll;
    height: 14em;
    padding: 10px;
    /* margin-top: .5em; */
}

button[class*=NumbersPanel-fab] {
    /* border-radius: 0px;
    background-color: unset;      
    width: 40px;
    font-size: 14px;
    font-weight: bolder;
    box-shadow: none;
    background-size: 90% 90% !important; */
}

button.indexButton {
    cursor: pointer;
}

button[class*=adv-not-visd] {
    border: 0px;
    color: #474747;
    background: url('/images/SVG/adv/adv-not_visited.svg');
    background-repeat: no-repeat;
}

button[class*=adv-ansd] {
    border: 0px;
    color: white;
    background: url('/images/SVG/adv/adv-correct.svg');
    background-repeat: no-repeat;
}

button[class*=adv-not-ansd] {
    border: 0px;
    color: white;
    background: url('/images/SVG/adv/adv-incorrect.svg');
    background-repeat: no-repeat;
}

button[class*=adv-mark-reviewd] {
    border: 0px;
    color: white;
    background: url('/images/SVG/mark-for-review.svg');
    /* background: url('/images/SVG/adv/adv-marked_review.svg'); */
    background-repeat: no-repeat;
}

button[class*=adv-ans-marked-review] {
    border: 0px;
    color: white;
    /* background: url('/images/SVG/adv/adv-answered_marked_review.svg'); */
    background: url('/images/SVG/answered_marked_for_review.svg');
    background-repeat: no-repeat;
}